.home-page {
    width: 100%;
    min-height: 100vh;
    background-color: #ff1a8d;
    background-attachment: fixed;
    background-position: top right;
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

.clients-page {
    min-height: calc(100vh - 70px);
    padding: calc(80px + 30px) 0 30px 0;
}

.clients-page .container {
    background-color: white;
    border-radius: 30px;
}

.clients-page .container .row {
    padding: 40px;
    gap: 24px;
}

.clients-page .container .box {
    border-color: transparent;
    box-shadow: 0 0 0.5rem rgba(0,0,0,.3);
    border-radius: 1rem;
}

.clients-page .container .box.box-filtros {
    padding: 25px 16px;
    display: flex;
    justify-content: space-between;
}

.clients-page .container .box.box-filtros .filtros {
    padding: 0 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
}

.clients-page .container .box.box-filtros .filtros select {
    width: 100%;
    height: 38px;
    min-width: 150px;
    padding: 5px 5px;
    border: 1px solid #ced4da;
    border-radius: 4px;
    background-color: white;
    color: black;
    text-align: left;
    overflow: hidden;
}

.clients-page .container .box.box-filtros .filtros select:focus-visible {
    outline-color: #ff1a8d;
}

.clients-page .container .box.box-filtros .filtros select option:hover {
    background-color: #ff1a8d;
    color: white;
}

.clients-page .container .box.box-filtros label {
    color: #ff1a8d;
    font-weight: bold;
}

.clients-page .container .box.box-filtros .buscar {
    padding: 0 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
}

.clients-page .container .box.box-filtros .buscar input {
    height: 38px;
    min-width: 150px;
    padding: 5px 10px;
    border: 1px solid #ced4da;
    border-radius: 4px;
    background-color: white;
}

.clients-page .container .box.box-filtros .buscar input:focus-visible {
    outline-color: #ff1a8d;
}

.clients-page .container .box.box-filtros .crear
.clients-page .container .box.box-categories .crear {
    display: flex;
    justify-content: center;
    align-items: center;
}

.clients-page .container .box.box-filtros .crear button,
.clients-page .container .box.box-categories .crear button {
    width: 100%;
    height: 38px;
    padding: 0 15px;
    background-color: #ff1a8d;
    border: 1px solid #ff1a8d;
    color: white;
    font-weight: bold;
    border-radius: 10px;
    line-height: 1;
    transition: all 0.2s;
}

.clients-page .container .box.box-filtros .crear button:hover,
.clients-page .container .box.box-categories .crear button:hover {
    background-color: white;
    border: 1px solid #ff1a8d;
    color: #ff1a8d;
    box-shadow: 0 0 0.5rem rgba(0,0,0,.3);
}

.box-clients-title {
    padding: 16px;
    font-size: 20px;
    font-weight: bold;
    color: #ff1a8d;
}

.box-categories-title {
    display: flex;
    justify-content: space-between;
    padding: 16px;
}

.box-categories-title h1 {
    font-size: 20px;
    font-weight: bold;
    color: #ff1a8d;
}

.box-clients-table {
    padding: 16px;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.box-clients-table .row {
    padding: 0 16px !important;
    gap: 0 !important;
}

.box-clients-table hr {
    width: 100%;
    margin: 0 0 1rem 0;
    border-top: 1px solid #ff1a8d;
    opacity: 1;
}

.box-categories-table {
    padding: 16px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.box-categories-table .row {
    padding: 0 16px !important;
    margin-top: 16px !important;
    gap: 0 !important;
}

.box-categories-table hr {
    width: 100%;
    margin: 0;
    border-top: 1px solid #ff1a8d;
    opacity: 1;
}

.box-clients-table-users {
    padding: 20px 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.box-clients-table-users-gray {
    padding: 20px 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: rgba(78,123,221,.10980392156862745);
}

.box-clients-table-title {
    text-align: center;
    color: #ff1a8d;
    border-bottom: 1px solid #ff1a8d;
    border-top: 1px solid #ff1a8d;
    font-weight: bold;
    padding: 12px;
}

.badge {
    font-family: Helvetica, Arial, sans-serif, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue;
}

.badge-danger {
    color: white;
    background-color: #dc3545;
}

.badge-success {
    color: white;
    background-color: #28a745;
}

.box-clients-table-users-buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.box-clients-table-users-button-edit {
    width: 50%;
    padding: 0 5px;
}

.box-clients-table-users-button-edit button {
    width: 100%;
    padding: 5px;
    background-color: darkblue;
    border: 1px solid darkblue;
    color: white;
    font-weight: bold;
    border-radius: 10px;
    transition: all 0.2s;
}

.box-clients-table-users-button-edit button:hover {
    background-color: white;
    border: 1px solid darkblue;
    color: darkblue;
    box-shadow: 0 0 0.5rem rgba(0,0,0,.3);
}

.box-clients-table-users-button-delete {
    width: 50%;
    padding: 0 5px;
}

.box-clients-table-users-button-delete button {
    width: 100%;
    padding: 5px;
    background-color: #dc3545;
    border: 1px solid #dc3545;
    color: white;
    font-weight: bold;
    border-radius: 10px;
    transition: all 0.2s;
}

.box-clients-table-users-button-delete button:hover {
    background-color: white;
    border: 1px solid #dc3545;
    color: #dc3545;
    box-shadow: 0 0 0.5rem rgba(0,0,0,.3);
}

.box-clients-table-users .spinner-border {
    color: #ff1a8d;
}

.box-categories-table .box-clients-table-users .spinner-border {
    margin-top: -16px !important;
}

.box-perfil-table {
    padding: 16px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.box-perfil-table .row {
    width: 100%;
    padding: 0 16px !important;
    margin-top: 16px !important;
    gap: 10px !important;
}

.box-perfil-table hr {
    width: 100%;
    margin: 0;
    border-top: 1px solid #ff1a8d;
    opacity: 1;
}

.box-perfil-items {
    padding: 0;
    width: auto;
    display: flex;
    justify-content: center;
    flex-direction: row;
    gap: 10px;
}

.box-perfil-item {
    width: auto;
    padding: 16px 24px;
    border-radius: 0.5rem;
    border: 0.0625rem solid rgba(128, 128, 128, 0.4);
    background-color: #f3f3f3;
    overflow: hidden;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
}

.box-perfil-item:hover {
    background-color: rgb(250, 250, 250);
}

.box-perfil-item svg {
    font-size: 25px;
}

.box-perfil-item span {
    font-weight: bold;
    font-size: 18px;
}

.box-perfil-item-pago {
    width: auto;
    padding: 0;
    border-radius: 0.5rem;
    border: 0.0625rem solid rgba(128, 128, 128, 0.4);
    background-color: #f3f3f3;
    overflow: hidden;
}

.box-perfil-item-pago-info {
    padding: 16px 24px;
}

.box-perfil-item-pago-info span {
    font-weight: bold;
}

.box-perfil-item-pago-info p {
    color: rgba(0,0,0,0.7);
    margin-bottom: 0;
}

.box-perfil-item-pago-active {
    background-color: #28a745;
    width: 100%;
    height: 0.5rem;
}

.box-perfil-item-pago-inactive {
    background-color: #dc3545;
    width: 100%;
    height: 0.5rem;
}

@media (max-width: 767px) {
    .clients-page .container .box.box-filtros {
        flex-direction: column;
        gap: 20px;
    }

    .box-clients-table-users-buttons {
        flex-direction: column;
        gap: 5px;
    }

    .box-clients-table-users-button-edit,
    .box-clients-table-users-button-delete {
        width: 100%;
    }

    .box-clients-table-title,
    .box-clients-table-users,
    .box-clients-table-users-gray,
    .client-modal-edit button,
    .client-modal-buttons {
        font-size: 14px;
    }

    .client-modal-title {
        font-size: 18px !important;
    }

    .client-modal-edit {
        gap: 12px !important;
        margin-top: 25px !important;
    }

    .badge-danger,
    .badge-success {
        font-size: 12px;
    }

    .box-categories-title {
        flex-direction: column;
        gap: 10px;
    }

    .box-perfil-table .row {
        padding: 0 !important;
    }

    .box-perfil-item-pago {
        width: 100%;
    }

    .box-perfil-item-pago-info p {
        font-size: 14px;
    }

    .box-perfil-items {
        flex-direction: column;
        width: 100%;
    }

    .box-perfil-item {
        gap: 10px;
    }

    .box-perfil-item span {
        font-size: 16px;
    }

    .box-perfil-item svg {
        font-size: 20px;
    }
}

@media (max-width: 575px) {
    .clients-page .container {
        width: calc(100% - 20px);
        margin-left: 10px;
        margin-right: 10px;
    }

    .clients-page .container .row {
        padding: 15px;
    }

    .box-clients-table {
        padding: 16px 0;
    }

    .box-clients-table .row {
        padding: 0 !important;
    }

    .box-clients-table-title,
    .box-clients-table-users,
    .box-clients-table-users-gray,
    .client-modal-edit button,
    .client-modal-buttons {
        font-size: 12px;
    }

    .client-modal-title {
        font-size: 16px !important;
    }

    .client-modal-edit {
        gap: 10px !important;
        margin-top: 20px !important;
    }

    .badge-danger,
    .badge-success {
        font-size: 12px;
    }

    .box-categories-table .row {
        width: 100%;
        flex-direction: column;
    }
}