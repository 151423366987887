.dashboard {
    max-width: 100%;
    min-height: 100vh;
    background-color: rgb(243, 244, 246);
}

.dashboard .container {
    min-height: calc(100vh - var(--navbar-height) - var(--footer-height));
    padding-top: 12px;
    padding-bottom: 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 30px;
}

.dashboard .container .row:nth-child(2) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.dashboard-boton {
    overflow: hidden;
}

.dashboard-boton img {
    width: 100%;
    overflow: hidden;
    transition: all 400ms;
}

.dashboard-boton:hover img {
    transform: scale(1.06);
    filter: contrast(50%);
}

.dashboard-status {
    width: max-content;
    display: flex;
    justify-content: center;
    font-weight: bold;
    color: white;
    font-family: Helvetica, Arial, sans-serif;
    padding: 0.35em 0.65em;
    border-radius: 0.25rem;
    line-height: 1;
}

.dashboard-status.status-active {
    background-color: #28a745;
}

.dashboard-status.status-inactive {
    background-color: #dc3545;
}

.dashboard-status.status-payday {
    background-color: rgb(24, 152, 211);
}

#qr-gen {
    display: none;
}

@media (max-width: 767px) {
    .dashboard-boton:nth-child(1) {
        padding-top: 2rem !important;
    }

    .dashboard-boton:nth-child(3) {
        padding-bottom: 2rem !important;
    }

    .dashboard .container {
        flex-direction: column-reverse;
        gap: 0;
    }

    .dashboard .container .row:nth-child(2) {
        margin-top: 2rem;
    }

    .dashboard .container {
        padding-top: 0;
        padding-bottom: 0;
    }
}