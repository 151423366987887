.client-modal {
    width: 100vw;
    height: 100vh;
    background-color: rgb(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
}

.client-modal-container {
    display: flex;
    justify-content: center;
    padding: 40px;
    width: 500px;
    max-width: calc(100vw - 20px);
    max-height: calc(100vh - 20px);
    background-color: white;
    box-shadow: 0 0 0.5rem rgba(0,0,0,.3);
    border-radius: 1rem;
    border-color: transparent;
    transition: all 0.2s;
}

.client-modal-children {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.client-modal-title {
    font-size: 20px;
    text-align: center;
    font-family: Helvetica, Arial, sans-serif, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue;
}

.client-modal-title span {
    font-weight: bold;
    color: #ff1a8d;
    word-wrap: break-word;
    line-height: 20px;
    max-width: 100%;
    overflow: hidden;
    overflow-wrap: break-word;
    word-break: break-word;
    font-family: Helvetica, Arial, sans-serif, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue;
}

.client-modal-buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-top: 30px;
}

.client-modal-buttons button {
    height: 38px;
    padding: 10px 30px;
    color: white;
    font-weight: bold;
    border-radius: 10px;
    line-height: 1;
    transition: all 0.2s;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Helvetica, Arial, sans-serif, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue;
}

.client-modal-buttons-red {
    background-color: #dc3545;
    border: 1px solid #dc3545;
}

.client-modal-buttons-blue {
    background-color: darkblue;
    border: 1px solid darkblue;
}

.client-modal-buttons button:disabled {
    background-color: rgba(239, 239, 239, 0.3);
    border-color: rgb(173 168 168 / 30%);
    color: gray;
}

.client-modal-buttons button:disabled:hover {
    background-color: rgba(239, 239, 239, 0.3);
    border-color: rgb(173 168 168 / 30%);
    color: gray;
    box-shadow: none;
    cursor: not-allowed;
}

.client-modal-buttons-blue-disabled,
.client-modal-buttons-red-disabled {
    background-color: -internal-light-dark(rgba(239, 239, 239, 0.3), rgba(19, 1, 1, 0.3));
    color: -internal-light-dark(rgba(16, 16, 16, 0.3), rgba(255, 255, 255, 0.3));
    border-color: -internal-light-dark(rgba(118, 118, 118, 0.3), rgba(195, 195, 195, 0.3));
}

.client-modal-buttons-red:hover {
    background-color: white;
    border: 1px solid #dc3545;
    color: #dc3545;
    box-shadow: 0 0 0.5rem rgba(0,0,0,.3);
}

.client-modal-buttons-blue:hover {
    background-color: white;
    border: 1px solid darkblue;
    color: darkblue;
    box-shadow: 0 0 0.5rem rgba(0,0,0,.3);
}

.client-modal-inputs {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    width: 100%;
}

.client-modal-inputs-row {
    padding: 0 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
    margin-top: 10px;
}

.client-modal-inputs-row .spinner-border {
    color: #ff1a8d;
}

.client-modal-inputs-row:nth-child(1){
    margin-top: 0;
}

.client-modal-inputs-row label {
    color: #ff1a8d;
    font-weight: bold;
    font-family: Helvetica, Arial, sans-serif, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue;
}

.client-modal-inputs-row input,
.client-modal-inputs-row select {
    height: 38px;
    min-width: 150px;
    padding: 5px 10px;
    border: 1px solid #ced4da;
    border-radius: 4px;
    background-color: white;
}

.client-modal-inputs-row input:focus-visible,
.client-modal-inputs-row select:focus-visible {
    outline-color: #ff1a8d;
}

.client-modal-inputs-row textarea {
    height: 76px;
    min-width: 150px;
    padding: 5px 10px;
    border: 1px solid #ced4da;
    border-radius: 4px;
    background-color: white;
    resize: none;
}

.client-modal-inputs-row textarea:focus-visible {
    outline-color: #ff1a8d;
}

.client-modal-inputs-errors {
    padding: 5px 15px;
    margin-bottom: 0;
    text-align: end;
    color: red;
}

.client-modal-edit {
    padding: 0 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
    margin-top: 30px;
}

.client-modal-edit button {
    height: 38px;
    padding: 10px 30px;
    color: white;
    background-color: #ff1a8d;
    border: 1px solid #ff1a8d;
    font-weight: bold;
    border-radius: 10px;
    line-height: 1;
    transition: all 0.2s;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Helvetica, Arial, sans-serif, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue;
}

.client-modal-edit button:hover {
    color: #ff1a8d;
    background-color: white;
}

@media (max-width: 575px) {
    .client-modal-container {
        padding: 40px 10px;
    }

    .client-modal-inputs-row,
    .client-modal-inputs-errors {
        font-size: 14px;
    }
}