.login-page {
    width: 100%;
    min-height: 100vh;
    background-color: #ff1a8d;
    background-attachment: fixed;
    background-position: top right;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
}

.login-page .container {
    display: flex;
    justify-content: center;
}

.spinnerContainerLogin {
    width: 100;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.spinnerContainerLogin .spinner-border {
    width: 64px !important;
    height: 64px !important;
}

.login-page form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 350px;
}

.login-page form img {
    width: 250px;
    height: 100px;
    margin-bottom: 30px;
}

.login-page-input-title {
    padding-bottom: 8px;
    padding-left: 4px;
    margin: 0;
}

.login-page-input-title-password {
    margin-top: 30px;
}

.input-field-login-username {
    display: block;
    height: 36px;
    border-radius: 4px;
    border: 2px solid #bebebe;
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: 6px 6px;
    background-image: url('../../../public/images/icon-username.png');
}

.input-field-login-password {
    display: block;
    height: 36px;
    border-radius: 4px;
    border: 2px solid #bebebe;
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: 6px 6px;
    background-image: url('../../../public/images/icon-password.png');
}

.login-page form input {
    border: 1px solid #fff;
    padding: 0 10px;
    margin: 4px 5px 0 15px;
    border-radius: 4px;
    width: 310px;
    height: 24px;
    background: transparent;
    box-shadow: none;
    outline: 0;
}

.login-page-input-error {
    border-radius: 4px;
    color: white;
    margin: 0;
    margin-top: 10px;
}

.login-page form button {
    font-weight: bold;
    background-color: white;
    color: #ff1a8d;
    border: 1px solid white;
    border-radius: 15px;
    height: 50px;
    margin-top: 50px;
    transition: all 0.2s;
    display: flex;
    justify-content: center;
    align-items: center;
}

.login-page form button:hover {
    background-color: #ff1a8d;
    color: white;
    box-shadow: 0px 4px 4px #e663a4;
}

.login-page-errors {
    padding: 15px;
    background-color: #4e0799;
    color: white;
    margin-top: 10px;
    border-radius: 10px;
    text-align: center;
}

@media (max-width: 480px) {
    .login-page form {
        width: 100%;
    }

    .login-page form input {
        width: calc(100% - 10px);
    }
}