.taskcard {
    padding: 30px 10px;
    text-align: center;
    background-color: rgb(231, 229, 229);
    border-radius: 15px;
}

.taskcard p {
    margin: 0;
}

.taskcard-buttons {
    margin-top: 10px;
    display: flex;
    justify-content: center;
    gap: 10px;
}

.taskcard-buttons button,
.taskcard-buttons a {
    color: white;
    border: none;
    padding: 10px 30px;
    border-radius: 10px;
    font-weight: bold;
}

.taskcard-buttons a {
    color: white;
    background-color: blue;
    text-decoration: none;
}

.taskcard-buttons a:hover {
    background-color: rgb(7, 7, 201);
}

.taskcard-buttons button:nth-child(2) {
    background-color: red;
}

.taskcard-buttons button:nth-child(2):hover {
    background-color: rgb(163, 6, 6);
}

.product-itemImg-box {
    min-width: 96px;
    height: 96px;
    margin-right: 12px;
    border-radius: 6px;
}

.product-itemImg-box img {
    height: 96px;
    width: 96px;
    max-width: 100%;
    object-fit: cover;
    border-radius: 6px;
}

.product-image-input-button {
    height: 38px;
    padding: 0 15px;
    background-color: #ff1a8d;
    border: 1px solid #ff1a8d;
    color: white;
    font-weight: bold;
    border-radius: 10px;
    line-height: 1;
    transition: all 0.2s;
    display: flex;
    align-items: center;
    justify-content: center;
}

.product-image-input-button:hover {
    border: 1px solid #ff1a8d;
    background-color: #ff1a8dd7;
    box-shadow: 0 0 0.5rem rgba(0,0,0,.3);
}

.product-image-input-rules-box {
    flex-direction: row;
}

.product-image-input-rules {
    font-size: 12px;
    color: #ff1a8d;
    margin-top: 5px;
}

@media screen and (max-width: 575px) {
    .product-image-input-rules-box {
        flex-direction: column;
    }

    .product-image-input-rules {
        text-align: center;
    }
}