.footer {
    padding: 20px 0 20px 0;
    height: 80px;
    max-width: 100vw;
    width: 100%;
    /* background-color: #4e0799; */
    /* background-color: black; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.footer.oscuro {
    background-color: black;
}

.footer a {
    font-family: Segma;
    height: auto;
    text-decoration: none;
    color: inherit;
    font-weight: bold;
}

.footer a:hover {
    text-decoration: underline;
    color: inherit;
}

.footer .href-imagen {
    height: 40px;
}

.footer img {
    height: 40px;
    transition: transform 400ms;
}

.footer img:hover {
    transform: scale(1.06);
}

.footer.oscuro img {
    filter: invert(1);
}

@media (max-width: 414px) {
    .footer .href-imagen,
    .footer img {
        height: 35px;
    }
}