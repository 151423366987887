@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap);

:root {
    --navbar-height: 80px;
    --footer-height: 80px;
}

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: Roboto, sans-serif;
}

.app {
    width: 100%;
    min-height: 100vh;
    background-color: rgb(243, 244, 246);
}

.app.oscuro {
    background-color: black;
}

.container {
    padding-left: 16px;
    padding-right: 16px;
}

.app .contenedor {
    min-height: calc(100vh - 16px - var(--navbar-height) - var(--footer-height));
}

.app .cuerpo {
    padding-bottom: 32px;
}

.spinnerContainer {
    height: calc(100vh - var(--navbar-height) - var(--footer-height));
    display: flex;
    justify-content: center;
    align-items: center;
}

.spinnerContainer.oscuro {
    color: white;
}

.spinnerContainer .spinner-border {
    width: 64px !important;
    height: 64px !important;
}

.float{
	position:fixed;
	width:60px;
	height:60px;
	bottom:30px;
	right:30px;
	background-color:#25d366;
	color:#FFF;
	border-radius:50px;
	text-align:center;
    font-size:30px;
	box-shadow: 2px 2px 3px #999;
    z-index:100;
    transition: all 0.3s ease;
}
.float:hover {
	text-decoration: none;
	color: #25d366;
    background-color:#fff;
}

.my-float{
	margin-top:16px;
}

@media (max-width: 767px) {
    .float {
        bottom: 20px;
        right: 20px;
    }
}