.categoryButton {
    width: auto !important;
    height: 42px;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 16px;
    padding-right: 16px;
    margin-right: 10px;
    margin-bottom: 10px;
    background-color: rgba(17,24,39,1);
    font-weight: 500;
    border-radius: 50px;
    border: 1px solid #e5e7eb;
    display: flex;
    justify-content: center;
    align-items: center;
}

.categoryButton:hover {
    box-shadow: 0 4px 6px -1px rgba(0,0,0,0.1),0 2px 4px -1px rgba(0,0,0,0.06);
}

.categoryButton svg {
    transition: all 0.2s ease-out;
}

.categoryButton svg:hover {
    transform: scale(1.4);
    cursor: pointer;
}

@media (max-width: 767px) {
    /* .categoryButton { 
        font-size: 14px;
        padding-left: 12px;
        padding-right: 12px;
    } */
}

@media (max-width: 575px) {

}